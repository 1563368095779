export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

export const NumberToString = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
})

export const splitByCapitalLetter = (str: string) =>
  str.split(/(?=[A-Z])/).join(' ')

export const monthNumberToMonthText = (n: string, shift = 0) => {
  let num = Number(n.split('-')[1]) + shift
  let year = Number(n.split('-')[0])

  if (num < 1) {
    num = 12
    year -= 1
  }
  if (num > 12) num = 1
  if (num < 1 || num > 12) return 'Invalid month' // Ensure the number is between 1 and 12
  const date = new Date(year, num - 1) // Month is 0-based in JavaScript Date
  return date.toLocaleString('en-US', { month: 'short', year: 'numeric' }) // "Jan", "Feb", etc.
}
